/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FaCogs, FaHome, FaSignOutAlt, FaUserShield } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useApp } from '../../providers/AppProvider';

import './LayoutAdmin.css';

interface LayoutAdminProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const LayoutAdmin: React.FC<LayoutAdminProps> = ({ children }) => {

	const app = useApp();
	const navigate = useNavigate();


	const logout = useCallback(() => {
		app.logout();
		navigate("/auth/admin");
	}, [app, navigate]);


	return (
		<div className='admin'>
			<header>
				<div className='logo'>
					NOME DO SISTEMA
				</div>

				<nav>
					<Link to="/admin/painel">
						<FaHome />
						Início
					</Link>
					<Link to="/admin/usuarios">
						<FaUserShield />
						Usuários
					</Link>
					<Link to="/admin/configuracoes">
						<FaCogs />
						Configurações
					</Link>
					<a onClick={logout}>
						<FaSignOutAlt />
						Sair
					</a>
				</nav>
			</header>

			<main>
				<nav>
					<div className="logo">
						LOGO
					</div>

					<Link to="/admin/painel">Início</Link>
					<Link to="/admin/painel">Início</Link>
				</nav>

				<section>
					{children}
				</section>
			</main>

			<footer>
				Motu Inteligência Digital &copy; 2022
			</footer>			
		</div>
	);
}

export default LayoutAdmin;