import React from 'react';
import { Navigate } from 'react-router-dom';
import LayoutDefault from '../layouts/Default/LayoutDefault';

interface RenderPageProps {
    isPrivate?: 'master' | 'admin' | 'user' | 'public';
    component: React.ComponentType;
    layout?: React.ComponentType;
}

const RenderPage: React.FC<RenderPageProps> = ({
	isPrivate = 'public',
	component: Component,
	layout: Layout = LayoutDefault
}) => {

	let adminLogado: boolean = (sessionStorage.getItem("adminToken") !== null)
	let userLogado: boolean = (sessionStorage.getItem("userToken") !== null)



	
	if (isPrivate === 'public') {
		return (	  
			<Layout>
				<Component />			
			</Layout>	 
		  );
	}

	if (isPrivate === 'admin' && adminLogado) {
		return (	  
			<Layout>
				<Component />			
			</Layout>	 
		  );
	}

	if (isPrivate === 'user' && userLogado) {
		return (	  
			<Layout>
				<Component />			
			</Layout>	 
		  );
	}

	if (isPrivate === 'master') {
		return (	  
			<Layout>
				<Component />			
			</Layout>	 
		  );
	}

	return <Navigate to={{	pathname: '/' }} />;
}

export default RenderPage;