import React, { useEffect } from 'react';
import { useApp } from '../../../providers/AppProvider';

import "./styles.css";

const Home: React.FC = () => {

	const app = useApp();


	useEffect(() => {
		async function carrega() {
			let result: any = await app.ajaxApi("post", "auth/admin", { email: "rodolfo@motu.com.br", senha: "123456" });
			console.log(result);
		}
		carrega();
	}, [app]);


  return (
	  <>
		<h1>Página HOME</h1>
		<p>{process.env.REACT_APP_NAME}</p>
	  </>
  );
}
export default Home;