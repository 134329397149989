import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthAdminForm from '../../../forms/AuthAdminForm';

import './AuthAdmin.css';

const AuthAdmin: React.FC = () => {

	const navigate = useNavigate();


	const loginSuccess = useCallback(() => {
		navigate("/admin/painel");
	}, [navigate]);


	return (
		<div className="auth-admin">
			<div className="box-login">
				<h1>Login ADMIN</h1>

				<AuthAdminForm onSuccess={loginSuccess} />
			</div>
		</div>
	);
}

export default AuthAdmin;