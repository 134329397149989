import React, { createContext, useContext, useCallback } from 'react';
import { notification } from 'antd';
import { api } from '../services/api';
import { Result } from '../interfaces/Result';
import ListError from '../components/ListError';
import { useNavigate } from 'react-router-dom';


interface AppProviderProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> { }

interface AppProps {		
	logout(): void;
	authAdmin(email: string, nome: string, token: string): void;
	ajaxApi(method: string, url: string, data?: object, notify?: boolean): Promise<Result | null>;
	ajaxApiAdmin(method: string, url: string, data?: object, notify?: boolean): Promise<Result | null>;
}

const AppContext = createContext<AppProps>({} as AppProps);


export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {

	const navigate = useNavigate();

	const logout = useCallback(() => {
		sessionStorage.removeItem("adminToken");
		sessionStorage.removeItem("adminEmail");
		sessionStorage.removeItem("adminNome");
	}, []);



	const ajax = useCallback(async (method: string, url: string, data?: any, token?: string | null, notify: boolean = true) => {
		try
		{
			let result: Result = {} as Result;
			let rs: any;
			method = method.toLowerCase();

			let headers: any = {};
			headers["Authorization"] = `Bearer ${token}`;

			if (process.env.REACT_APP_TENANT != null)
				headers["Tenant"] = process.env.REACT_APP_TENANT;

			if (method === "get")
				rs = await api.get(url, { headers: headers});
			else if (method === "post") 
				rs = await api.post(url, data, { headers: headers });
			else if (method === "put")
				rs = await api.put(url, data, { headers: headers });
			else if (method === "delete") 
				rs = await api.delete(url, { headers: headers });

			result = rs.data;
			return result;
		}
		catch (e: any) 
		{
			switch(e.response.status) {
				case 401:
					notification.warning({ 
						message: "Acesso não autorizado",		
						description: "Realize o login novamente",				
						duration: 10
					  });
					logout();
					navigate("/auth/admin");
			}

			console.log(e.response.status);
			let errorResult: Result = e.response.data;
			console.log(errorResult);

			// if (errorResult.code === 500) {
			// 	notification.error({ 
			// 		message: errorResult.mensagem,
			// 		description: <ListError errors={errorResult.dados} />,
			// 		duration: 10
			// 	  });
				
			// 	return null;
			// }
			
			
			if (notify) {
				notification.warning({ 
					message: errorResult.mensagem,
					description: <ListError errors={errorResult.dados} />,
					duration: 10
				  });
			}
			
			return errorResult;
		}
		
	}, [logout, navigate]);



	const ajaxApi = useCallback(async (method: string, url: string, data?:any, notify: boolean = true) => {
		let result:Result | null = await ajax(method, url, data, null, notify);
		console.log(result);
		return result;
	}, [ajax]);


	// ***** ADMIN
	const authAdmin = useCallback(async (email: string, nome: string, token: string) => {
		sessionStorage.setItem("adminToken", token);
		sessionStorage.setItem("adminEmail", email);
		sessionStorage.setItem("adminNome", nome);
	}, []);


	const ajaxApiAdmin = useCallback(async (method: string, url: string, data?:any, notify: boolean = true) => {
		
		
			let result: Result = {} as Result;
			let token = sessionStorage.getItem("adminToken");

			if (token == null) {
				navigate("/");
				return null;
			}
			result = await ajax(method, url, data, token, notify);
			return result;
		
	}, [navigate, ajax]);


	


	return (
		<AppContext.Provider value={{ logout, ajaxApi, authAdmin, ajaxApiAdmin }}>			
			{children}
		</AppContext.Provider>
	);
};

export function useApp(): AppProps {
	const context = useContext(AppContext);

	if (!context) {
		throw new Error('useApp deve ser usado em um AppProvider');
	}

	return context;
}
