import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { FaBan, FaCheck } from 'react-icons/fa';
import FormProps from '../../interfaces/FormProps';
import { Result } from '../../interfaces/Result';
import { useApp } from '../../providers/AppProvider';


const AdminForm: React.FC<FormProps> = ({
	onSuccess,
	onClose,
	data
}) => {

	const app = useApp();

	const [loading, setLoading] = useState(false);


	const submit = async (formData: any) => {
		setLoading(true);
		let result: Result | null;

		if (data) // ALTERAR
			result = await app.ajaxApiAdmin("put", `admin/admin/${data.id}`, formData);
		else // INSERIR
			result = await app.ajaxApiAdmin("post", "admin/admin", formData);

		if (result?.code === 200) {
			if (onSuccess)
				onSuccess(result);
		}

		setLoading(false);
	};


	return (
		<>
			<h2>{data ? 'Editar Usuário' : 'Novo Usuário'}</h2>
			<Form layout='vertical' onFinish={submit} initialValues={data}>
				<Form.Item label="Nome" name="nome" rules={[
					{ required: true, message: "Informe o nome" }
				]}>
					<Input />
				</Form.Item>

				<Form.Item label="E-mail" name="email" rules={[
					{ required: true, message: "Informe o e-mail" }
				]}>
					<Input />
				</Form.Item>
				
				{!data && (
					<Row gutter={20}>
						<Col span={24} md={12}>
							<Form.Item label="Senha" name="senha" rules={[
								{ required: true, message: "Informe a senha" }
							]}>
								<Input type="password" />
							</Form.Item>
						</Col>
						<Col span={24} md={12}>
							<Form.Item label="Confirme a senha" name="senhaConfirm" rules={[
								{ required: true, message: "Confirme a senha" }
							]}>
								<Input type="password" />
							</Form.Item>
						</Col>
					</Row>	
				)}
							

				<Form.Item valuePropName="checked" name="ativo">
					<Checkbox>Ativo</Checkbox>
				</Form.Item>

				<div className="acoes">
					{onClose && (
						<Button  className='button-default' onClick={onClose} type="primary" icon={<FaBan />}>Cancelar</Button>
					)}
					<Button loading={loading} htmlType='submit' className='button-success' type="primary" icon={<FaCheck />}>Salvar</Button>
				</div>
			</Form>
		</>
	);
}

export default AdminForm;