import { Col, Input, message, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaCogs } from 'react-icons/fa';
import { Config } from '../../../entities/config';
import { ConfigCategoria } from '../../../entities/config-categoria';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';

import "./Configuracoes.css";

const Configuracoes: React.FC = () => {

	// ***** Providers
	const app = useApp();


	// ***** States
	const [categorias, setCategorias] = useState<ConfigCategoria[]>([]);


	// FUNÇÕES
	const carregaConfiguracoes = useCallback(async() => {
		let result: Result | null = await app.ajaxApiAdmin("get", "admin/config");
		console.log(result);
		if (result?.code === 200)
			setCategorias(result.dados);
	}, [app]);


	const alteraConfig = useCallback(async(categoria: number, chave: string, valor: string) => {
		// Verifica se a categoria existe
		let rsCategoria =  categorias.find((categ: ConfigCategoria) => {
			if (categ.id === categoria)
				return true;
			
			return false;
		});
		if (!rsCategoria)
			return;

		// Procura a configuração na categoria informada
		let rsConfig = rsCategoria.configs.find((conf: Config) => {
			if (conf.chave === chave)
				return true;

			return false;
		});

		if (!rsConfig)
			return;

		if (rsConfig.valor === valor)
			return;

		let result: Result | null = await app.ajaxApiAdmin("put", "admin/config", {
			chave,
			valor
		});

		if (result?.code === 200)
			message.success("Configuração atualizada!");
	}, [app, categorias]);


	useEffect(() => {
		carregaConfiguracoes();
	}, [carregaConfiguracoes]);



  	return (
	  	<>
	  		<h1>
			  <FaCogs />
			  Configurações
			</h1>

			<Row gutter={[20, 20]}>				
				{categorias.map((categoria: ConfigCategoria) => (
					<Col md={12} key={categoria.id}>
						<div className='box'>
							<h2>{categoria.nome}</h2>

							{categoria.configs.map((config: Config) => (
								<div className='campo-config' key={config.id}>
									<p>{config.nome}:</p>
									<Input defaultValue={config.valor} onBlur={(value) => alteraConfig(categoria.id, config.chave, value.currentTarget.value)} />
								</div>
							))}							
						</div>
					</Col>
				))}	

				<Col md={12}>
					<div className="box">
						<h2>Variáveis de Ambiente (AWS)</h2>
						<div className="campo-config">
							<p>Tenant:</p>
							<Input value={process.env.REACT_APP_TENANT} readOnly />
						</div>

						<div className="campo-config">
							<p>URL API:</p>
							<Input value={process.env.REACT_APP_URL_API} readOnly />
						</div>

						<div className="campo-config">
							<p>URL (CloudFront):</p>
							<Input value={process.env.REACT_APP_CLOUDFRONT} readOnly />
						</div>
					</div>
				</Col>			
				
			</Row>
			
	  	</>
  	);
}

export default Configuracoes;