import { Button, Modal, Popconfirm, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaPlus, FaTrash, FaUserShield } from 'react-icons/fa';
import { Admin } from '../../../entities/admin';
import AdminForm from '../../../forms/Admin/AdminForm';
import { useApp } from '../../../providers/AppProvider';


const Usuarios: React.FC = () => {

	const app = useApp();

	const [admins, setAdmins] = useState<Admin[]>();
	const [modalVisible, setModalVisible] = useState(false);
	const [admin, setAdmin] = useState<Admin | null>(null);


	// ********************** Define a estrutura dos dados e ações da tabela
	const columns: ColumnsType<Admin>  = [
		{
			title: "Nome",
			dataIndex: "nome"		
		},
		{
			title: "E-mail",
			dataIndex: "email"
		},
		{
			title: "Ativo",		
			render: (admin: Admin) => (
				<>
					{admin.ativo === true ? (
						<Tag color="green">Ativo</Tag>
					) : (
						<Tag color="red">Inativo</Tag>
					)}
				</>
			)
		},
		{
			title: "",		
			render: (admin: Admin) => (
				<>
					<Popconfirm  placement="topRight" title="Você confirma a EXCLUSÃO deste registro?" onConfirm={() => excluirUsuario(admin)}>
						<Button size='small' danger type='primary'>
							<FaTrash />
							Excluir
						</Button>
					</Popconfirm>
					<Button size='small' type='primary' onClick={() => editarUsuario(admin)}>
						<FaEdit />
						Editar
					</Button>
				</>
			)
		},
	];


	// ********************** Carrega lista de usuários (ADMIN)
	const carregaUsuarios = useCallback(async () => {
		let result: any = await app.ajaxApiAdmin("get", "admin/admin");			
		setAdmins(result.dados);
	}, [app]);


	// ********************** Abre o modal para inserir novo usuário (ADMIN)
	const novoUsuario = useCallback(() => {
		setModalVisible(true);
		setAdmin(null);
	}, []);


	// ********************** Função executada no sucesso da Inserção ou Atualização dos dados do usuário (ADMIN)
	const callbackSuccess = useCallback((dados: any) => {
		setModalVisible(false);
		carregaUsuarios();
	}, [carregaUsuarios]);


	// ********************** Exclui usuário (ADMIN)
	const excluirUsuario = useCallback(async (admin: Admin) => {
		await app.ajaxApiAdmin("delete", `admin/admin/${admin.id}`);
		carregaUsuarios();
	}, [app, carregaUsuarios]);


	// ********************** Abre o modal para a atualização dos dados do usuário (ADMIN)	
	const editarUsuario = useCallback((admin: Admin) => {
		setAdmin(admin);
		setModalVisible(true);
	}, []);


	// ***** Funções executadas ao iniciar a página
	useEffect(() => {
		carregaUsuarios();
	}, [carregaUsuarios]);


	
	return (
		<>
			<h1>
				<FaUserShield />
				Usuários do Sistema
			</h1>

			<div className='submenu'>
				<nav>
					<Button onClick={novoUsuario} className='button-success' type='primary' icon={<FaPlus />}>Novo Usuário</Button>
				</nav>
			</div>

			<Table columns={columns} dataSource={admins} />

			<Modal destroyOnClose visible={modalVisible} closable onCancel={() => setModalVisible(false)} footer={false}>
				<AdminForm onClose={() => setModalVisible(false)} onSuccess={callbackSuccess} data={admin} />
			</Modal>
		</>
	);
}

export default Usuarios;