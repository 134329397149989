import React from 'react';
import { Route, Routes as RoutesDOM } from "react-router-dom";
import LayoutAdmin from '../layouts/Admin/LayoutAdmin';
import LayoutBlank from '../layouts/Blank/LayoutBlank';
import Arquivos from '../pages/Admin/Arquivos/Arquivos';
import Configuracoes from '../pages/Admin/Configuracoes/Configuracoes';
import Painel from '../pages/Admin/Painel/Painel';
import Usuarios from '../pages/Admin/Usuarios/Usuarios';
import AuthAdmin from '../pages/Auth/AuthAdmin/AuthAdmin';
import Home from '../pages/Public/Home/Home';
import RenderPage from './RenderPage';

const Routes: React.FC = () => {

	return (
		<RoutesDOM>
			<Route path='/' element={<RenderPage component={Home} />}></Route>

			<Route path='/auth/admin' element={<RenderPage component={AuthAdmin} layout={LayoutBlank} />}></Route>
			<Route path='/admin/painel' element={<RenderPage component={Painel} isPrivate="admin" layout={LayoutAdmin} />}></Route>
			<Route path='/admin/usuarios' element={<RenderPage component={Usuarios} isPrivate="admin" layout={LayoutAdmin} />}></Route>
			<Route path='/admin/configuracoes' element={<RenderPage component={Configuracoes} isPrivate="admin" layout={LayoutAdmin} />}></Route>
			<Route path='/admin/arquivos' element={<RenderPage component={Arquivos} isPrivate="admin" layout={LayoutAdmin} />}></Route>
		</RoutesDOM>
	);
}

export default Routes;